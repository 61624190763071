/* eslint-disable spellcheck/spell-checker */
import Typography from "@material-ui/core/Typography";
import parse from "html-react-parser";
import { handleContactLink } from "src/utils/handleContactLink";

type Props = {
  infoType: "bottominfo" | "topinfo" | "contactinfo";
  country: string;
};

const OTPFederationInfo: React.FC<Props> = ({ infoType, country }) => {
  if (infoType === "topinfo") {
    let message;
    switch (country) {
      case "co":
        message =
          "Selecciona con cuál de tus datos deseas identificarte para realizar la vinculación de tu cuenta.";
        break;
      case "pe":
        message =
          "Selecciona tu identificación para acceder con un correo no registrado";
        break;
      default:
        message =
          "Selecciona con cuál de tu datos deseas identificarte para proceder con el vinculo de logins.";
        break;
    }

    let headerText =
      "Correo electrónico no registrado en Consultoría de Belleza Natura y Avon ";
    if (country === "mx") {
      headerText =
        "Correo electrónico no registrado en Consultoría de Belleza Natura ";
    }
    return (
      <>
        <Typography
          variant="h1"
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#454545",
            marginBottom: "30px",
          }}
        >
          {parse(headerText)}
        </Typography>

        <Typography
          variant="caption"
          style={{
            fontSize: "15px",
            color: "#454545",
            fontWeight: "500",
          }}
        >
          {parse(message)}
        </Typography>
      </>
    );
  }

  if (infoType === "bottominfo") {
    const message =
      country === "co"
        ? "La próxima vez, podrás iniciar sesión directamente utilizando el método elegido."
        : "La próxima vez, podrá iniciar sesión directamente utilizando el método que elija.";

    return (
      <Typography
        variant="h1"
        style={{
          fontSize: "15px",
          fontWeight: 500,
          color: "#454545",
          marginBottom: "30px",
        }}
      >
        {parse(message)}
      </Typography>
    );
  }

  let message =
    "Para actualizar tus datos, contacta a nuestra Asistente Virtual por WhatsApp al número +55 11 93038-0000.";

  if (infoType === "contactinfo") {
    switch (country) {
      case "co":
        message = `Para actualizar tus datos, contacta a nuestro Asistente Virtual por WhatsApp al número +55 11 93038-0000 o accede <a href="${"https://wa.me/5511930380000"}">aquí</a>.`;
        break;
      case "cl":
        message = `Para actualizar tus datos, contacta a nuestra Asistente Virtual por WhatsApp al número +55 11 93038-0000 ou acede ao seguinte enlace: <a href="${handleContactLink(
          country
        )}">clic aquí</a>.`;
        break;
      case "mx":
        message = `Si no cuentas con tu código de consultor contacta a nuestra Asistente virtual por WhatsApp al número +551193038-0000 ou si deseas actualizar tus datos, da: <a href="${handleContactLink(
          country
        )}">clic aquí</a>.`;
        break;
      default:
        break;
    }

    return (
      <Typography
        variant="caption"
        style={{
          fontSize: "13px",
          letterSpacing: "0.2px",
          color: "GrayText",
        }}
      >
        {parse(message)}
      </Typography>
    );
  }
  return null;
};

export default OTPFederationInfo;
